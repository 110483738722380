import React from "react";

const InstagramIcon = () => {
  return (
    <svg viewBox="0 0 27 27">
      <path
        d="M19.2114 2.19714H8.2255C5.19182 2.19714 2.73254 4.65642 2.73254 7.6901V18.676C2.73254 21.7097 5.19182 24.169 8.2255 24.169H19.2114C22.2451 24.169 24.7044 21.7097 24.7044 18.676V7.6901C24.7044 4.65642 22.2451 2.19714 19.2114 2.19714Z"
        strokeWidth="1.22686"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.1128 12.491C18.2484 13.4053 18.0922 14.3391 17.6665 15.1595C17.2408 15.9799 16.5672 16.6452 15.7416 17.0608C14.916 17.4763 13.9804 17.621 13.0678 17.4741C12.1553 17.3273 11.3122 16.8964 10.6587 16.2429C10.0051 15.5893 9.57424 14.7463 9.4274 13.8337C9.28055 12.9212 9.4252 11.9855 9.84075 11.1599C10.2563 10.3343 10.9216 9.66074 11.742 9.23504C12.5625 8.80934 13.4962 8.65317 14.4105 8.78875C15.3431 8.92704 16.2066 9.36162 16.8732 10.0283C17.5399 10.695 17.9745 11.5584 18.1128 12.491Z"
        strokeWidth="1.22686"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.7607 7.14087H19.7717"
        strokeWidth="1.22686"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default InstagramIcon;
