import React from "react";

const HireMe = () => {
  return (
    <div className="hire-me">
      <a
        href="https://www.linkedin.com/in/shahir-hossaini/"
        target="_blank"
        rel="noreferrer"
      >
        Hire Me! Find me in Linkden
      </a>
    </div>
  );
};

export default HireMe;
