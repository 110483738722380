import React from "react";

const TwitterIcon = () => {
  return (
    <svg viewBox="0 0 27 27">
      <g clipPath="url(#clip0_1_153)">
        <path
          d="M25.4368 3.29572C24.3847 4.03779 23.2199 4.60536 21.9872 4.97656C21.3255 4.2158 20.4462 3.67659 19.4682 3.43186C18.4901 3.18713 17.4604 3.24869 16.5185 3.60821C15.5765 3.96774 14.7677 4.60787 14.2015 5.44205C13.6352 6.27623 13.3387 7.26419 13.3523 8.27233V9.37092C11.4216 9.42098 9.50859 8.9928 7.78351 8.12451C6.05843 7.25622 4.57487 5.97478 3.46494 4.39431C3.46494 4.39431 -0.92942 14.2816 8.9579 18.676C6.69538 20.2118 4.00013 20.9818 1.26776 20.8732C11.1551 26.3661 23.2396 20.8732 23.2396 8.23937C23.2386 7.93336 23.2091 7.62811 23.1517 7.32754C24.2729 6.2218 25.0641 4.82574 25.4368 3.29572V3.29572Z"
          strokeWidth="1.22686"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_153">
          <rect width="27" height="27" transform="translate(0.169189)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TwitterIcon;
