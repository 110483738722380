import React, { Component } from "react";

class CodeSnippets extends Component {
  state = {
    codeSnippets: [
      {
        title: "Social Media Buttons with HTML and CSS (blur effect)",
        link: "ZEWvqRq",
      },
      { title: "Team section using HTML, CSS, and JS", link: "RwRPpYd" },
      { title: "Card like countdown using HTML, CSS and JS", link: "abNQgqp" },
      { title: "Tab navigation using HTML, CSS, and JS", link: "gOwjVXb" },
      { title: "Service section using Html and CSS", link: "abZYXOM" },
      { title: "Social Media Links using Html, and CSS", link: "KKzYgJK" },
      { title: "Navigation bar using Html, CSS and JS", link: "MWyEENW" },
      {
        title: "How to create Custom Home Slider using HTML, CSS, and JS",
        link: "rNMgaob",
      },
      { title: "Fab buttons using Html, CSS, and JS", link: "JjXZyqE" },
      {
        title: "Animated Stack Words using HTML, CSS, and JS",
        link: "NWNpbqK",
      },
      {
        title: "Animated Fab-Button using HTML | CSS | pure JavaScript",
        link: "QWGwNqG",
      },
      {
        title: "Custome toggle switch button using Html, and CSS",
        link: "bGwRYmb",
      },
      { title: "Smooth Scrolling with Javascript", link: "zYqNozW" },
      { title: "Toggle button using HTML, CSS and JS", link: "BaLKJJX" },
      { title: "Head navigation using HTML, and CSS", link: "OJRvEba" },
      { title: "Loading animation using Html, and CSS", link: "ZEpaxYq" },
      { title: "Rounded input with Html and CSS", link: "LYNjYjJ" },
      { title: "Hover Styled Button using HTML, and CSS", link: "KKoBLNx" },
    ],
  };

  render() {
    const { codeSnippets } = this.state;
    return (
      <div className="code-snippets">
        <div className="code-header">
          <h1>Find some HTML, CSS, and Javascript source codes below 👇🏽</h1>
        </div>
        <ul className="code-items" id="code-items">
          {codeSnippets?.map((code, i) => (
            <li key={i}>
              <a
                href={`https://codepen.io/shahirhossaini/pen/` + code.link}
                target="_blank"
                rel="noreferrer"
              >
                {code.title}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default CodeSnippets;
