import React from "react";

const Quote = () => {
  return (
    <div className="quote">
      <h1>Code with ❤️, Share your Knowledge 🌏, Have fun with Coding ✌️</h1>
    </div>
  );
};

export default Quote;
