import React, { Component } from "react";

class Projects extends Component {
  state = {
    projects: [
      {
        id: 1,
        name: "Aria University CMS",
        link: "profileProduct/604f376ae4226116e10b6623",
      },
      {
        id: 2,
        name: "GDG Kabul DevFest19 Website",
        link: "profile_cases/603f42d7593d832c8731bd31",
      },
      {
        id: 3,
        name: "Small Bussiness CMS",
        link: "profile_cases/603f42d7593d832c8731bd31",
      },
      {
        id: 4,
        name: "Embassy Of USA ELCLCs CMS",
        link: "profile_cases/603f4188593d832c8731bd30",
      },
    ],
  };

  render() {
    const { projects } = this.state;

    return (
      <div className="projects">
        <div className="projects-header">
          <h1>Take a look 👀 at what I have done in the past!</h1>
        </div>

        <div className="projects-cards">
          {projects?.map(({ id, name, link }) => (
            <div className={"project-card-item color-" + id} key={id}>
              <h2>
                {name}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={"https://saawis.com/" + link}
                >
                  🔗
                </a>
              </h2>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default Projects;
