import React from "react";

const EmailIcon = () => {
  return (
    <svg viewBox="0 0 27 27">
      <path
        d="M5.0283 4.39441H22.6057C23.8142 4.39441 24.8029 5.38314 24.8029 6.59159V19.7747C24.8029 20.9831 23.8142 21.9719 22.6057 21.9719H5.0283C3.81985 21.9719 2.83112 20.9831 2.83112 19.7747V6.59159C2.83112 5.38314 3.81985 4.39441 5.0283 4.39441Z"
        strokeWidth="1.22686"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.8029 6.59155L13.817 14.2817L2.83112 6.59155"
        strokeWidth="1.22686"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EmailIcon;
