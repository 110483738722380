import React, { useEffect } from "react";
import Header from "./components/header.component";
import Home from "./components/home.component";
import Quote from "./components/quote.component";
import Projects from "./components/projects.component";
import CodeSnippets from "./components/snippets.component";
import HireMe from "./components/hireme.component";
import Footer from "./components/footer.component";
import OpenSourcedProjects from "./components/opensourced.component";

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyASIc06MvxwCoHAh8ZwDmSi9BGCwnmLkYo",
  authDomain: "shahirdev-acfd4.firebaseapp.com",
  projectId: "shahirdev-acfd4",
  storageBucket: "shahirdev-acfd4.appspot.com",
  messagingSenderId: "913380899438",
  appId: "1:913380899438:web:c5377b9834c9fcf0a0047f",
  measurementId: "G-5Y0WFSGJSG",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const App = () => {
  useEffect(() => {
    console.log(analytics);
  }, []);

  return (
    <div className="main-container">
      <Header />
      <Home />
      <Quote />
      <Projects />
      <CodeSnippets />
      <OpenSourcedProjects />
      <HireMe />
      <Footer />
    </div>
  );
};

export default App;
