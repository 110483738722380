import React from "react";
import LinkdenIcon from "../svg/icon.linkden";
import EmailIcon from "../svg/icon.email";
import InstagramIcon from "../svg/icon.instagram";
import TwitterIcon from "../svg/icon.twitter";

const getCurrentYear = () => {
  let currentYear = new Date().getFullYear();

  return currentYear;
};

const Footer = () => {
  return (
    <div className="footer">
      <aside className="left-side">
        <p>&copy; Shahir.dev {getCurrentYear()}</p>
      </aside>
      <aside className="right-side">
        <a href="mailto:hossainishahir@gmail.com">
          <EmailIcon />
        </a>
        <a href="https://www.linkedin.com/in/shahir-hossaini/">
          <LinkdenIcon />
        </a>
        <a href="https://www.instagram.com/shahirhussaini/?hl=en">
          <InstagramIcon />
        </a>
        <a href="https://twitter.com/DevShahir">
          <TwitterIcon />
        </a>
      </aside>
    </div>
  );
};

export default Footer;
