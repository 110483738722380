import React from "react";

const LinkdenIcon = () => {
  return (
    <svg viewBox="0 0 28 27">
      <path
        d="M7.49312 9.88733H3.09875V23.0704H7.49312V9.88733Z"
        strokeWidth="1.22686"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.479 8.7887C20.2272 8.7887 21.9038 9.48316 23.1399 10.7193C24.3761 11.9555 25.0705 13.6321 25.0705 15.3802V23.0704H20.6762V15.3802C20.6762 14.7975 20.4447 14.2387 20.0326 13.8266C19.6206 13.4145 19.0617 13.1831 18.479 13.1831C17.8963 13.1831 17.3374 13.4145 16.9254 13.8266C16.5133 14.2387 16.2818 14.7975 16.2818 15.3802V23.0704H11.8875V15.3802C11.8875 13.6321 12.5819 11.9555 13.8181 10.7193C15.0542 9.48316 16.7308 8.7887 18.479 8.7887V8.7887Z"
        strokeWidth="1.22686"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.29594 6.59151C6.50941 6.59151 7.49312 5.6078 7.49312 4.39433C7.49312 3.18086 6.50941 2.19714 5.29594 2.19714C4.08247 2.19714 3.09875 3.18086 3.09875 4.39433C3.09875 5.6078 4.08247 6.59151 5.29594 6.59151Z"
        strokeWidth="1.22686"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LinkdenIcon;
