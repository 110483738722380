import React, { Component } from "react";

class OpenSourcedProjects extends Component {
  state = {
    Projects: [
      {
        title: "box-shadow CSS generator using React.js",
        link: "box-shadow-css-generator",
      },
      { title: "`Flexbox in CSS` using React.js", link: "flexboxincss" },
    ],
  };

  render() {
    const { Projects } = this.state;

    return (
      <div className="code-snippets">
        <div className="code-header">
          <h1>Open Sourced Project using React.js ⚛️</h1>
        </div>
        <ul className="code-items" id="code-items">
          {Projects?.map((code, i) => (
            <li key={i}>
              <a
                href={`https://` + code.link + `.netlify.app`}
                rel="noreferrer"
                target="_blank"
              >
                {code.title}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default OpenSourcedProjects;
