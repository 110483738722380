import React from "react";

import profile from "../images/profile-picture.png";

const Home = () => {
  return (
    <div className="home">
      <aside className="left-side">
        <div className="introduction">
          <h1>Hi I'm Shahir</h1>
          <p>
            I'm a Web Developer,
            <a
              href="https://gdg.community.dev/gdg-balkh/"
              target="_blank"
              rel="noreferrer"
            >
              GDG community organizer
            </a>
            , working on GDE and in deep ❤️ with Javascript.
          </p>

          <a
            href="https://www.linkedin.com/in/shahir-hossaini/"
            className="contact-me-link"
            target="_blank"
            rel="noreferrer"
          >
            Connect with me
          </a>
        </div>
      </aside>
      <aside className="right-side">
        <img
          className="profile-photo"
          src={profile}
          alt="Shahir Hussaini Profile "
          loading="lazy"
        />
      </aside>
    </div>
  );
};

export default Home;
